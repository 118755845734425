.wscards-container{
    display: flex;
    align-items: center;
    margin:30px;
    justify-content: space-between;

}

.wscards-item{
    flex-basis: 32%!important;
}

.progress-bar{
    display: inline-block;
    /* height: 86px; */
    height: 82px;
    /* width:86px; */
    width:82px;
    background-color: #fff;
}

.wscardInside{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1% 5% ;

}

/* .wscardInside .wscard-left{
   flex-basis:70%;
}
.wscardInside .wscard-right{
    flex-basis:30%;
} */



.ws-number{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
}

.status{
    font-size: 14px;
    margin-bottom: 4px;
}

.power{
    font-size: 14px;
    
}
/* .power span{
    color:#0091ad;
    
} */
.pneumatics{
    font-size: 14px;
}

/* .pneumatics span{
    color:#0091ad;
    
} */

/* .wscards-item:first-child .MuiPaper-elevation1{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
} */
.active-card.MuiPaper-elevation1{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.wsCardOutside{
    display: flex;
    flex-direction: column;
}
.wsCardOutside .bottom{
    display: flex;
    padding: 14px 24px;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    color:#fff;
  
}

.inventoryRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fff;

}

.wsCardOutside .bottom.first{
    background: linear-gradient(
        60deg
        , #66bb6a, #43a047);
            box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}
.wsCardOutside .bottom.second{
    background: linear-gradient(
        60deg
        , #26c6da, #00acc1);
            box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}
.wsCardOutside .bottom.third{
    background: linear-gradient(
60deg
, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%);
}

.wsCardsLink{
    text-decoration: none;
}