
    
    .report {background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%)}
    .report{
        width:175px;
        /* width:200px; */
       margin: 10px;
       padding: 15px 15px;
       text-align: center;
       text-transform: uppercase;
       transition: 0.5s;
       background-size: 200% auto;
       color: white;            
       box-shadow: 0 0 20px #eee;
       border-radius: 10px;
       display: block;
       font-size: 14px;
     }

     .report:hover {
       background-position: right center; /* change the direction of the change here */
       color: #fff;
       text-decoration: none;
       cursor: pointer;
     }

     .buttonContainer{
         margin:0 30px;
         display: flex;
         align-items: center;
         justify-content: space-between;
     }

     .buttonContainer:hover{
       cursor:pointer;
     }

     .buttonContainer .title{
         font-size: 22px;
         margin-left: 20px;
         color:#555;
     }


     .overall-analysis{
      margin:40px;
     }

     .parameter-detail{
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin:20px;
      

     }

     .overall-analysis-inner{
      display: flex;
      align-items: center;
     }

     .overall-analysis-inner .left{
      margin:40px;
       flex-basis: 50%;
     }
     .overall-analysis-inner .right{
       flex-basis: 50%;
     }


     .overall-analysis-inner .right .graph-item{
       margin:60px;
     }

     
    
