.wsSecondRow-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
}

.secondRow-left {
    flex-basis: 57%;
}
.inventoryRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fff;

}

/*.secondRow-right{*/
/*    flex-basis: 50%;*/
/*}*/


.wsSecondRow-container .ct-series-a .ct-line {
    /* Set the colour of this series line */
    stroke: rgba(57, 71, 199, 0.829);
    /* Control the thikness of your lines */
    stroke-width: 4px;

}

.wsSecondRow-container .ct-series-a .ct-point {
    stroke: rgba(57, 71, 199, 0.829);
}

.wsSecondRow-container .chartItem {
    width: 90%;
    margin: 20px auto 40px;

    /* margin-top: 20px;
    margin-bottom: 40px; */
}

.wsSecondRow-container .chartItem p {
    text-align: center;
    font-size: 20px;
    padding: 10px;
}

.ws-name {
    margin-top: 14px;
    text-align: center;
}

.secondRow-left {
    display: flex;
    flex-direction: column;
}

.secondRow-left .bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.secondRow-left .bottom .cardItem {
    width: 22%;
}


.secondRow-left .bottom .cardItem p {
    text-align: center;
}

.secondRow-left .bottom .cardItem p.title {
    margin-top: 8px;
    margin-bottom: 0;
}

.secondRow-left .bottom .cardItem p.value {
    margin-bottom: 8px;
    margin-top: 4px;
}

/* .secondRow-left .bottom .MuiPaper-elevation1{

box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


} */

/* .secondRow-left .bottom .cardItem:nth-child(4) .MuiPaper-elevation1{
  background: linear-gradient(
  60deg
  , #26c6da, #00acc1);
      box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}
.secondRow-left .bottom .cardItem:nth-child(1) .MuiPaper-elevation1{
  background: linear-gradient(
60deg
, #ffa726, #fb8c00);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%);
}
.secondRow-left .bottom .cardItem:nth-child(2) .MuiPaper-elevation1{
  background: linear-gradient(
      60deg
      , #66bb6a, #43a047);
          box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}
.secondRow-left .bottom .cardItem:nth-child(3) .MuiPaper-elevation1{
  background: linear-gradient(
      60deg
      , #ef5350, #e53935);
          box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%);
} */

.WithStyles\(ForwardRef\(LinearProgress\)\)-bar-24 {
    background: linear-gradient(
            60deg, #26c6da, #00acc1);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}


.secondRow-left .bottom {
    font-weight: 600;
}

/* .secondRow-left .bottom .title{
  color:#fff;

}
.secondRow-left .bottom .value{
  color:#fff;

} */


.pb-item {
    margin: 10px 0;
    width: 100%
}

.pb-container {
    margin: 6px 33px;
    /* height: 280px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .secondRow-right{
  height: 302px;
} */

.process-name {
    font-size: 0.8rem;
    font-weight: 600;
    color: #555;
}

.sub-process {
    margin: 7px 0;
    font-size: 14px;
}

/* .graph-parameters-info{
    width:86%;
    margin: 10px auto;
}
.graph-parameters-info .above{
   display: flex;
   align-items: center;

   margin: 10px 0;

}
.graph-parameters-info .bottom1{
  display: flex;
  align-items: center;
  margin: 10px 0;

}
.graph-parameters-info .bottom1 >div{
width:50%;
display: flex;
align-items: center;
}

.graph-parameters-info .above> div{
    width:50%;
    display: flex;
    align-items: center;
}

.graph-legend{

    display: inline-block;
    width:24px;
    height:16px;
    background-color: aqua;
    margin-right: 6px;
    border-radius: 6px;

}

.performance .graph-legend{
background-color: rgba(57, 71, 199, 0.829);
}
.availability .graph-legend{
background-color: #f4c63d;
}
.quality .graph-legend{
background-color: #f05b4f;
}
.oee .graph-legend{
background-color:#d17905;
}

.quality,.oee,.performance,.availability{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graph-parameters-info .value{
    margin-right: 40px;
    font-size: 14px;
    font-style: italic;
} */

.sub-process {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.sub-process div:nth-child(2) {
    font-style: italic;
    color: rgb(75, 121, 180);
}

.order-details {
    /* padding:10px 0; */
    position: absolute;
    background: linear-gradient(
            60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%);


    color: #fff;
    text-align: center;
    top: -20px;
    left: 5%;
    width: 90%;
    border-radius: 6px;
    /* margin: 10px 0; */


}

.order-details p {
    color: #fff;
    font-size: 16px;
    margin: 16px 0;
}

.pb-container {
    padding-top: 36px;
    /* position: relative; */
}

.secondRow-right {
    margin-top: 12px;
    position: relative;
}


.graph-parameters-info {
    display: flex;
    width: 100%;
    /* margin:30px auto; */
}

.graph-parameters-info > div {
    padding: 4px;
    margin: 10px;
    border-radius: 6px;
    width: 25%;
    font-size: 16px;
    text-align: center;
    /* color:#fff; */
}

/* .graph-parameters-info div div:first-child::after{
    content: "";
    display: block;
    width:30px;
    height: 4px;
    margin: 4px auto;
    background-color: crimson;

} */


.graph-parameters-info > div:first-child {
    background: linear-gradient(
            60deg, #26c6da, #00acc1);

    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}


.graph-parameters-info > div:nth-child(2) {
    background: linear-gradient(
            60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%);
}

.graph-parameters-info > div:nth-child(3) {
    background: linear-gradient(
            60deg, #66bb6a, #43a047);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);

}


.graph-parameters-info > div:nth-child(4) {
    background: linear-gradient(
            60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%);
}

.graph-parameters-info {
    position: absolute;
    bottom: -40px;
    width: 90%;
    left: 5%;
    color: #fff;
}

.secondRow-left .top {
    position: relative;
    margin-bottom: 3%;
}

.graph-parameters-info > div {
    position: relative;
}

.order-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}

.order-status div:nth-child(2) {
    font-style: italic;
    color: rgb(75, 121, 180);
}

.secondRow-left .bottom .cardItem p.title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.onelineitem {
    /*display:block;*/
    color: #939393;
    justify-content: space-between;
}

.stream-img {
    border-radius: 10px;
    box-shadow: 5px 5px 10px #969696;
    border: 1px solid white;
    width: 90%;
    height:auto;
    /*width: 650px;*/

}

.stream-card {
    /*height: 46%;*/
    text-align: center;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 3px #c9c9c9;
    /*position: center;*/
}