

.login-container{
    display: flex;
    /* height: 100vh; */
    align-items:flex-start;
    /* width:80%; */
    margin: 0 14%;
    /* width:80%; */
    /* padding-top: 60px; */
    padding: 140px 60px 40px 40px;
    background: #fff;
    border-radius: 10px;
    

}
.login-outside-container{
    /* background: #BE93C5; 
background: -webkit-linear-gradient(to right, #7BC6CC, #BE93C5);
background: linear-gradient(to right, #7BC6CC, #BE93C5);  */
background: #2193b0;  
background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);  
background: linear-gradient(to right, #6dd5ed, #2193b0); 



    padding: 40px 0;
    display: flex;
    min-height: 100vh;
    align-items: center;
    /* width:80%; */
    /* margin: 0 10%; */
    /* padding-top: 60px; */
    justify-content: center;
    

}

.login-container .login-left{
    flex-basis: 60%;
}
.login-container .login-left img{
    width:100%;
    transform: translateY(20%);
}
.login-container .login-right{
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .login-container .login-right .login-details{
    width:100%;
}

.login-container .login-right .login-details form{
    width:100%;
} */

.form-group-custom{
    margin: 20px 0;
}
.form-group-custom input{
    padding: 18px 50px;
    width:300px;
    border-radius:40px;
    background: #e6e6e6;
    border:none;
}

.form-group-custom:last-child input{
    background-color:rgb(40, 40, 150);
    color:#fff;
    cursor: pointer;
}

.login-details p{
    font-size: 24px;
    text-align: center;
}

.form-group-custom{
    position: relative;
}

.form-group-custom .symbol{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:20px;
}

.form-group-custom input:focus{
    outline: none!important;
    border: none!important;
}

input:focus{
    outline: none!important;
}



.form-group-custom:last-child input{background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
.form-group-custom:last-child input{
   /* margin: 10px; */
   /* padding: 15px 45px; */
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   padding: 18px 50px;
   width:300px;
   border-radius:40px;
   margin-top: 30px;
 }

 .form-group-custom:last-child input:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
  
 }

 p.forgot{
     font-size: 16px;
     margin:0;
     cursor: pointer;
 }

 div.create{
     font-size: 18px;
     margin-top:100px;
     cursor: pointer;
     text-align: center;
 }

 div.create:hover, p.forgot:hover{
color:#4776E6;
 }

 .fa-long-arrow-alt-right{
     margin:8px;
 }

.login-container{
    position: relative;
}

.login-image{
    top:0;
    width:100%;
    left:0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-image-logo{
    width:160px;
}

.login-image-logo img{
    width:100%;
    padding-top: 20px;
}

