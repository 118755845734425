.signup{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.signup input{
    display: block;
}

.signup.first .form-group-signup{
    flex-basis: 48%;
  
}
.signup.first .form-group-signup input{
    width: 100%;
  
}
.signup.second .form-group-signup{
    flex-basis: 48%;
  
}
.signup.second .form-group-signup input{
    width: 100%;
  
}
.signup.fifth .form-group-signup{
    flex-basis: 48%;
  
}
.signup.fifth .form-group-signup input{
    width: 100%;
  
}




.signup.third .form-group-signup{
    flex-basis: 100%;
  
}
.signup.third .form-group-signup input{
width:100%;
}

.signup.fourth .form-group-signup{
    flex-basis: 30%;
  
}
.signup.fourth .form-group-signup input{
width:100%;
}


.signup-outside-container{
    min-height: 100vh;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2193b0;  
background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);  
background: linear-gradient(to right, #6dd5ed, #2193b0); 
}

.signup-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width:60%;
    padding: 60px 40px;
    border-radius: 10px;
}

.form-group-signup{
    margin:10px 0;
}

.form-group-signup input{
    padding:14px 20px 14px 44px;
    border-radius: 10px;
    border-radius:40px;
    background: #e6e6e6;
    border:none;
}

.form-group-signup input:focus{
    outline: none;
    border:none
    
}

.form-group-signup{
    position: relative;
}

.form-group-signup .symbol-s{
    position: absolute;
    top:34px;
    left:20px;
}

.signup-title{
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}


.signup-btn input{
    background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%);
    border:none;
}
.signup-btn input{
   
   /* padding: 15px 45px; */
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   padding: 18px 60px;
   /* width:10px; */
   /* border-radius:40px; */
   margin-top: 40px;
 }

 .signup-btn input:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
  
 }

 .signup-group{
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .already-account-login{
    margin-top: 40px;
    margin-right: 40px;
 }

 .already-account-login:hover{
     cursor:pointer;
     color:#4776E6;
 }

 .signup-btn input:hover{
     cursor:pointer;
 }