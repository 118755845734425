.old-password input,.new-password input{
  width:260px;
  display: block;
  padding: 16px ;
  margin: 10px 0;
  border-radius: 10px;
  border:none;
  background: #e6e6e6;
}

.modal-container{
  padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* padding-bottom: 40px; */
}

.password-modal{
  border: none;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  /* color:#555; */

}

.password-modal:hover{
  cursor: pointer;
}

.makeStyles-paper-126 {
border: none!important;
border-radius: 10px!important;
}

.password-btn-save input{
  width:260px;
  display: block;
  padding: 16px ;
  margin: 10px 0;
  border-radius: 10px;
  border:none;
  color:#fff;
  font-size: 16px;
  background-color: #118ab2;
}



.close-password{
  position: absolute;
  top:20px;
  right:20px;
}

.close-password:hover{
  cursor: pointer;
}

.password-btn-save input:hover{
  cursor: pointer;
}


