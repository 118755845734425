h2{
    text-align: center;
    padding-top:20px;
}

.menuItem{
    height:50px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    margin:0px 14px 10px 14px;
    color:#000;

    border-radius: 4px;
}



.menuItem.active{
    box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%);
    background-color: #00acc1;
    color:#fff;
}





.menuItem .menuLeft{
    margin-right: 14px;
    margin-left: 10px;
}




.subRight{
    background-color: #eeeeee;
    min-height: 100vh;
}

.subLeft{
    color:#fff
}


.menuWrapper{
    /*margin-top: 20px;*/
}

.menuItem{
    display: flex;
    align-items: center;
}
.close-drawer-btn{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color:white;
    text-align: center;
    align-content: center;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border: 4px #008bff solid;
    box-shadow: 2px 2px 10px #d4d4d4;

}

.black-icon{
    color:black;
}