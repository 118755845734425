.orderContainer{
    margin:120px 30px;
    
}

.orderContainer h3{
    text-align: center;
    color:#555;
    font-size: 30px;
    margin-bottom: 20px;
}

.orderContainer{
    padding: 0 60px;
}

.orderContainer table{
    /* width:90%; */
    margin:auto;
}

thead{
    /* background: rgb(108, 122, 224); */
    background: linear-gradient(
        60deg
        , #26c6da, #00acc1);
            /* box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
    color:#fff; */
    color:#fff;

}

thead th,tbody td{
    padding:18px;
}

thead th:first-child{
    border-top-left-radius: 10px;
}
thead th:last-child{
    border-top-right-radius: 10px;
}

tbody{
    background-color: #fff;
}

table{
    border-radius: 10px;
    border-spacing: 0 1px;
    
}

tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}
tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

tbody tr:hover{
    cursor: pointer;
    background-color: rgb(236, 241, 253);
}

tbody td{
    padding-left: 60px;
    text-align: center;
    /* width: 100%; */
}

thead th{
   
  text-align: center;
    padding-left: 60px;
}

/* table{
    overflow-y:scroll;
    height:500px;
    display:block;
    width:100%
 } */

 /* .table-outside{
    overflow-y:scroll;
    height:500px;
 } */

 .grid{
     position: relative;
     width:100%;
 }

 .grid-container {
    overflow-y: auto;
    height: 500px;
    }

    table{
        width:100%;
        /* display: block; */
    }

    thead{
        position: sticky;
        top:0
    }
/* 
    ::-webkit-scrollbar{
        width:10px; 
    }

    ::-webkit-scrollbar-track{
        background: #00acc1;
        border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb{
        background: #6600ff;
        border-radius: 50px;
    } */