.userProfileContainer{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 30px;
}

.userDetailsLeft{
    padding: 20px 0;
    width:90%;
    
}

.userDetailsLeft h2{
    text-align: left;
    margin-left: 40px;
    margin-top: 4px;
    margin-bottom: 0;
}
.userDetailsLeft h4{
    text-align: right;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: 20px;
}
.userDetailsRight{
    width:30%;
}

form{
    width:100%;
}
.userDetailsLeft input{
border:1px solid rgb(168, 168, 168);
display: block;
width:100%;
margin: 10px 0;
padding: 10px 15px;
border-radius: 20px;
}

.userDetailsLeft input:focus{
    border:1px solid rebeccapurple!important;
}

.flexRow{
    margin:14px 0;
    display: block;
    width:100%;
    display: flex;
    justify-content: space-between;
}

.user-name,.email-address,.first-name,.last-name{
 width:46%;
}

.city,.country,.postal-code{
    width:30%;
}

.address{
    width:100%;
}

.address input{
    width: 100%;
}

/* .form-container{
    display: flex;
    flex-direction: column;
} */

.form-container{
    width: 100%;
    margin: 20px 50px;
}

.inner-container{
    margin:20px;
}

.profile-info{
    text-align: center;
    margin-bottom: 40px;
}

.profile-info .fullname{
    font-size: 24px;
    margin:10px 0;
}
.profile-info .username{
    font-size: 18px;
    color:#555;
    margin:10px 0;
}
.profile-info .email{
    font-size: 16px;
    margin:10px 0;
}

.profile-pic{
    height:120px;
    width: 120px;
    /* background-color: rebeccapurple; */
    /* background-image: url('...\assets\img\photo.jpg'); */
    border-radius: 50%;
}

.profile-pic img{
    width:100%;
    height: 100%;
    border-radius: 50%;
}

.profile-pic-container{
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-pic{
    position: relative;
    /* border:2px solid rgb(151, 151, 151); */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.change-profile{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width:30px;
    height: 30px;
    background:#fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
    top:70%;
    left:70%;
    /* color:#fff; */
}

.password_update{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 50px 40px; 
}

.update_btn{
    padding: 12px 20px;
    border: none;
    color:white;
    background-color: #118ab2;
    border-radius: 6px;
    font-size: 16px;
}

.update_btn:hover{
    cursor: pointer;
}

.MuiPaper-elevation1{
  padding: 10px 0px 0px;
}