.notifications-container{
    margin: 30px;
}

.noti-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #00cae3;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 188 212 / 40%);
    /* width: 100%; */
    margin:30px 30px;
    border-radius: 6px;
    color:#fff;
}

.noti-item .icon{
    width:10%;
}
.noti-item .noti-text{
    width:55%;
}
.noti-item .noti-time{
    width:30%;
}
.noti-item .close{
    width:5%;
}

/* .noti-time{
    display: flex;
    align-items: center;
} */

.noti-time{
    font-size: 14px;
}